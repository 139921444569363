import React from 'react'
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout'

function Loading() {
	return <div>Loading...</div>
}

const Dashboard = Loadable({
	loader: () => import('./views/Dashboard'),
	loading: Loading,
})

const UploadPhotoImage = Loadable({
	loader: () => import('./views/UploadPhotoImage'),
	loading: Loading,
})

const ManualMatching = Loadable({
	loader: () => import('./views/ManualMatching'),
	loading: Loading,
})

const MatchingPhotos = Loadable({
	loader: () => import('./views/MatchingPhotos'),
	loading: Loading,
})

const AthleteManagement = Loadable({
	loader: () => import('./views/AthleteManagement'),
	loading: Loading,
})

const EventManagement = Loadable({
	loader: () => import('./views/EventManagement'),
	loading: Loading,
})

const ChallengeManagement = Loadable({
	loader: () => import('./views/ChallengeManagement/ChallengeManagement.js'),
	loading: Loading,
})

const MasterChallengeCategory = Loadable({
	loader: () =>
		import('./views/MasterChallengeCategory/MasterChallengeCategory.js'),
	loading: Loading,
})

const BlogManagement = Loadable({
	loader: () => import('./views/BlogManagement'),
	loading: Loading,
})

const RacepakManagement = Loadable({
	loader: () => import('./views/RacepakManagement'),
	loading: Loading,
})

const PengambilanRacepak = Loadable({
	loader: () => import('./views/PengambilanRacepak'),
	loading: Loading,
})

const CompanyManagement = Loadable({
	loader: () => import('./views/CompanyManagement'),
	loading: Loading,
})

// const UnmatchedPhotos = Loadable({
//   loader: () => import('./views/UnmatchedPhotos'),
//   loading: Loading,
// });

const UserManagement = Loadable({
	loader: () => import('./views/UserManagement'),
	loading: Loading,
})

const RoleManagement = Loadable({
	loader: () => import('./views/RoleManagement'),
	loading: Loading,
})

const EventPhotos = Loadable({
	loader: () => import('./views/EventPhotos'),
	loading: Loading,
})

const Profile = Loadable({
	loader: () => import('./views/Profile'),
	loading: Loading,
})

const PaymentManagement = Loadable({
	loader: () => import('./views/PaymentManagement'),
	loading: Loading,
})

const DashboardEventReport = Loadable({
	loader: () => import('./views/DashboardEventOrg/Report'),
	loading: Loading,
})

const DashboardEventGrafik = Loadable({
	loader: () => import('./views/DashboardEventOrg/Grafik'),
	loading: Loading,
})

const VoucherMaster = Loadable({
	loader: () => import('./views/VoucherManagement/VoucherMaster'),
	loading: Loading,
})

const VoucherMasterVirtual = Loadable({
	loader: () =>
		import(
			'./views/VoucherManagement/VoucherMasterVirtual/VoucherMasterVirtual'
		),
	loading: Loading,
})

const VoucherData = Loadable({
	loader: () => import('./views/VoucherManagement/VoucherData'),
	loading: Loading,
})

const VoucherDataVirtual = Loadable({
	loader: () =>
		import('./views/VoucherManagement/VoucherDataVirtual/VoucherDataVirtual'),
	loading: Loading,
})

const Ticketbox = Loadable({
	loader: () => import('./views/Ticketbox'),
	loading: Loading,
})

const DashboardTicketbox = Loadable({
	loader: () => import('./views/DashboardTicketbox'),
	loading: Loading,
})

const DashboardRacepak = Loadable({
	loader: () => import('./views/DashboardRacepak'),
	loading: Loading,
})

const MasterCategory = Loadable({
	loader: () => import('./views/MasterCategory'),
	loading: Loading,
})

const PageChallenge = Loadable({
	loader: () => import('./views/ParticipantChallenge/PageChallenge'),
	loading: Loading,
})

const LegalInfoManagement = Loadable({
	loader: () => import('./views/LegalInfoManagement/LegalInfoManagement'),
	loading: Loading,
})

const EventVirtual = Loadable({
	loader: () =>
		import('./views/EventVirtualManagement/EventVirtual/EventVirtual'),
	loading: Loading,
})

const RacepakVirtual = Loadable({
	loader: () => import('./views/EventVirtualManagement/RacepakVirtual'),
	loading: Loading,
})
const PaymentVirtual = Loadable({
	loader: () => import('./views/EventVirtualManagement/PaymentVirtual'),
	loading: Loading,
})
const PesertaVirtual = Loadable({
	loader: () => import('./views/EventVirtualManagement/PesertaVirtualRun'),
	loading: Loading,
})

const MasterEkspedisi = Loadable({
	loader: () => import('./views/EventVirtualManagement/MasterEkspedisi'),
	loading: Loading,
})

const WatermarkManagement = Loadable({
	loader: () => import('./views/MatchingPhotos/Watermark/WatermarkManagement'),
	loading: Loading,
})

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
	{ path: '/', exact: true, name: 'Home', component: DefaultLayout },
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard },
	{
		path: '/upload-photo-image',
		exact: true,
		name: 'Upload Photo Image',
		component: UploadPhotoImage,
	},
	{
		path: '/matching-photos',
		exact: true,
		name: 'Matching Photos',
		component: MatchingPhotos,
	},
	{
		path: '/athlete-management',
		exact: true,
		name: 'Athlete Management',
		component: AthleteManagement,
	},
	{ path: '/event', exact: true, name: 'Event', component: EventManagement },
	{
		path: '/challenge',
		exact: true,
		name: 'Challenge',
		component: ChallengeManagement,
	},
	{
		path: '/master-challenge-category',
		exact: true,
		name: 'Master Challenge Category',
		component: MasterChallengeCategory,
	},
	{ path: '/blog', exact: true, name: 'Blog', component: BlogManagement },
	{
		path: '/company',
		exact: true,
		name: 'Company',
		component: CompanyManagement,
	},
	{
		path: '/racepak-management',
		exact: true,
		name: 'Racepak Management',
		component: RacepakManagement,
	},
	{
		path: '/racepak-guntime',
		exact: true,
		name: 'Racepak GunTime',
		component: RacepakManagement,
	},
	{
		path: '/pengambilan-racepak',
		exact: true,
		name: 'Pengambilan Racepak',
		component: PengambilanRacepak,
	},
	{
		path: '/unmatched-photos',
		exact: true,
		name: 'Unmatched Photos',
		component: MatchingPhotos,
	},
	{
		path: '/manual-matching',
		exact: true,
		name: 'Manual Matching',
		component: ManualMatching,
	},
	{
		path: '/user-management',
		exact: true,
		name: 'User Management',
		component: UserManagement,
	},
	{
		path: '/role-management',
		exact: true,
		name: 'Role Management',
		component: RoleManagement,
	},
	{
		path: '/payment-management',
		exact: true,
		name: 'Payment Management',
		component: PaymentManagement,
	},
	{
		path: '/event-photos',
		exact: true,
		name: 'Event Photos',
		component: EventPhotos,
	},
	{ path: '/profile', exact: true, name: 'Admin Profile', component: Profile },
	{
		path: '/dashboard-eo',
		exact: true,
		name: 'Dashboard Report',
		component: DashboardEventReport,
	},
	{
		path: '/dashboard-eo/report',
		exact: true,
		name: 'Dashboard Report',
		component: DashboardEventReport,
	},
	{
		path: '/dashboard-eo/grafik',
		exact: true,
		name: 'Dashboard Grafik',
		component: DashboardEventGrafik,
	},
	{
		path: '/voucher-master',
		exact: true,
		name: 'Voucher Master',
		component: VoucherMaster,
	},
	{
		path: '/voucher-master-virtual',
		exact: true,
		name: 'Voucher Master Virtual',
		component: VoucherMasterVirtual,
	},
	{
		path: '/voucher-data',
		exact: true,
		name: 'Voucher Data',
		component: VoucherData,
	},
	{
		path: '/voucher-data-virtual',
		exact: true,
		name: 'Voucher Data Virtual',
		component: VoucherDataVirtual,
	},
	{ path: '/ticketbox', exact: true, name: 'Ticketbox', component: Ticketbox },
	{
		path: '/dashboard-ticketbox',
		exact: true,
		name: 'Dashboard Ticketbox',
		component: DashboardTicketbox,
	},
	{
		path: '/dashboard-racepak',
		exact: true,
		name: 'Dashboard Racepak',
		component: DashboardRacepak,
	},
	{
		path: '/master-category',
		exact: true,
		name: 'Master Category',
		component: MasterCategory,
	},
	{
		path: '/participant-challenge',
		exact: true,
		name: 'Master Category',
		component: PageChallenge,
	},
	{
		path: '/legal-info-management',
		exact: true,
		name: 'Legal Info Management',
		component: LegalInfoManagement,
	},
	{
		path: '/event-virtual/event',
		exact: true,
		name: 'Event Virtual',
		component: EventVirtual,
	},
	{
		path: '/event-virtual/payment',
		exact: true,
		name: 'Payment Virtual',
		component: PaymentVirtual,
	},
	{
		path: '/event-virtual/peserta',
		exact: true,
		name: 'Peserta Virtual',
		component: PesertaVirtual,
	},
	{
		path: '/event-virtual/racepak',
		exact: true,
		name: 'Racepak Virtual',
		component: RacepakVirtual,
	},
	{
		path: '/event-virtual/master-ekspedisi',
		exact: true,
		name: 'Master Ekspedisi',
		component: MasterEkspedisi,
	},
	{
		path: '/watermark-management',
		exact: true,
		name: 'Watermark Management',
		component: WatermarkManagement,
	},
]

export default routes
