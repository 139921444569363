import React, { Component } from 'react'
import {
	Button,
	Card,
	CardBody,
	CardGroup,
	Col,
	Container,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from 'reactstrap'
import JwtHelpers from '../../../utils/JwtHelpers'
import Inputs from '../../../utils/Inputs'
import ApiCall from '../../../services/ApiCall'
import Calls from '../../../utils/Calls'

class Login extends Component {
	constructor(props) {
		super(props)
		this.inputs = new Inputs(this)
		this.state = {
			// holder value Input data
			formData: {
				email: '',
				password: '',
			},
		}
	}

	componentDidMount() {
		if (!JwtHelpers.redirectUser(JwtHelpers.getCurrentUser())) {
			if (JwtHelpers.getCurrentUser()) {
				JwtHelpers.removeCurrentToken()
			}
		}
	}

	postLogin = e => {
		const { formData } = this.state
		e.preventDefault()
		Calls.defaultError(
			ApiCall.postLogin(formData).then(response => {
				const user = JwtHelpers.setCurrentToken(response.data.token)
				JwtHelpers.redirectUser(user)
			}),
		)
	}

	render() {
		const { formData } = this.state
		if (JwtHelpers.getCurrentUser()) {
			return <div />
		}
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="8">
							<CardGroup>
								<Card className="p-4">
									<CardBody>
										<Form onSubmit={this.postLogin}>
											<h1>Login</h1>
											<p className="text-muted">Sign In to your account</p>
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="icon-user" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name={'email'}
													type="text"
													placeholder="Email"
													autoComplete="email"
													value={formData.email}
													onChange={e => this.inputs.handleChange(e)}
												/>
											</InputGroup>
											<InputGroup className="mb-4">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="icon-lock" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name={'password'}
													type="password"
													placeholder="Password"
													autoComplete="current-password"
													value={formData.password}
													onChange={e => this.inputs.handleChange(e)}
												/>
											</InputGroup>
											<Row>
												<Col xs="6">
													<Button color="primary" className="px-4">
														Login
													</Button>
												</Col>
												{/* <Col xs="6" className="text-right">*/}
												{/* <Button color="link" className="px-0">Forgot password?</Button>*/}
												{/* </Col>*/}
											</Row>
										</Form>
									</CardBody>
								</Card>
								{/* <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>*/}
								{/* <CardBody className="text-center">*/}
								{/* <div>*/}
								{/* <h2>Sign up</h2>*/}
								{/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut*/}
								{/* labore et dolore magna aliqua.</p>*/}
								{/* <Button color="primary" className="mt-3" active>Register Now!</Button>*/}
								{/* </div>*/}
								{/* </CardBody>*/}
								{/* </Card>*/}
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

export default Login
