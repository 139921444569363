import swal from 'sweetalert2'
import JwtHelpers from './JwtHelpers'

function defaultError(apiCall, configs) {
	const curConfigs = Object.assign({}, configs)
	return apiCall.catch(error => {
		if (curConfigs.onError !== undefined && curConfigs.onError !== null) {
			if (curConfigs.onError(error)) {
				return
			}
		}
		if (error.response) {
			if (
				error.response.status === 403 &&
				error.response.data.message === 'Failed to authenticate token.'
			) {
				JwtHelpers.removeCurrentToken()
				JwtHelpers.redirectUser()
			} else if (
				error.response.status === 403 &&
				error.response.data.message ===
					'Password telah diubah, silahkan login kembali.'
			) {
				JwtHelpers.removeCurrentToken()
				JwtHelpers.redirectUser()
				swal('Oops...', error.response.data.message, 'error')
			} else if (
				error.response.status === 403 &&
				error.response.data.message === 'Illegal Role Navigation'
			) {
				JwtHelpers.removeCurrentToken()
				JwtHelpers.redirectUser()
			} else if (
				error.response.status === 403 &&
				error.response.data.message ===
					'Illegal Access, User tidak ditemukan. Silahkan login kembali.'
			) {
				JwtHelpers.removeCurrentToken()
				JwtHelpers.redirectUser()
				swal('Oops...', error.response.data.message, 'error')
			} else if (
				error.response.status === 400 &&
				error.response.data.message === 'Token expired.'
			) {
				JwtHelpers.removeCurrentToken()
				JwtHelpers.redirectUser()
			} else if (
				error.response.status === 400 &&
				error.response.data.message === 'Role expired.'
			) {
				JwtHelpers.setCurrentToken(error.response.data.token)
				window.location.reload()
			} else {
				swal('Oops...', error.response.data.message, 'error')
			}
		} else {
			swal('Oops...', 'No Internet Connection', 'error')
		}
	})
}

export default {
	defaultError,
}
