import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import {
	AppBreadcrumb,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarMinimizer,
	AppSidebarNav,
} from '@coreui/react'
import JwtHelpers from '../../utils/JwtHelpers'
// routes config
import routes from '../../routes'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'
import ObjectHelpers from '../../utils/ObjectHelpers'

class DefaultLayout extends Component {
	isPathAllowed = (path, navigations) => {
		let nav
		for (let i = 0; i < navigations.length; i++) {
			nav = navigations[i]
			if (this.isSpecialPath(path)) {
				return true
			}
			if (nav.url === path) {
				return true
			}
			if (nav.children && nav.children.length > 0) {
				if (this.isPathAllowed(path, nav.children)) {
					return true
				}
			}
		}
		return false
	}

	isSpecialPath = path => {
		return path === '/profile' && JwtHelpers.getCurrentUser()
	}

	render() {
		const user = JwtHelpers.getCurrentUser()
		const navigations = ObjectHelpers.getValueByKey(
			user,
			'Role.Navigations',
			[],
		)
		return (
			<div className="app">
				<AppHeader fixed>
					<DefaultHeader history={this.props.history} />
				</AppHeader>
				<div className="app-body">
					<AppSidebar fixed display="lg">
						<AppSidebarHeader />
						<AppSidebarForm />
						<AppSidebarNav
							navConfig={{
								items: navigations,
							}}
							{...this.props}
						/>
						<AppSidebarFooter />
						<AppSidebarMinimizer />
					</AppSidebar>
					<main className="main">
						<AppBreadcrumb appRoutes={routes} />
						<Container fluid>
							<Switch>
								{routes.map((route, idx) => {
									if (route.component) {
										if (this.isPathAllowed(route.path, navigations)) {
											return (
												<Route
													key={idx}
													path={route.path}
													exact={route.exact}
													name={route.name}
													render={props => <route.component {...props} />}
												/>
											)
										}
									}
									return null
								})}
								<Redirect from="/" to="/login" />
							</Switch>
						</Container>
					</main>
					{/* <AppAside fixed>*/}
					{/* <DefaultAside />*/}
					{/* </AppAside>*/}
				</div>
				<AppFooter>
					<DefaultFooter />
				</AppFooter>
			</div>
		)
	}
}

export default DefaultLayout
