const ENV = process.env.REACT_APP_BUILD_ENV || 'staging'

const url = {
	production: process.env.REACT_APP_BASE_API_OLD || 'https://api2.beyondrun.com/api/v1',
	staging: 'https://api2.beyondrun.com/api/v1',
	development: 'http://localhost:4000/api/v1',
	stagingNew: process.env.REACT_APP_BASE_API_OLD,
}

const newURL = {
	production: process.env.REACT_APP_BASE_API || 'https://api-prod-new.beyondrun.com/v1',
	staging: 'https://api-prod-new2.beyondrun.com/v1',
	development: 'https://api-prod-new2.beyondrun.com/v1',
	stagingNew: process.env.REACT_APP_BASE_API,
}

const urlStorage = {
	production:
		process.env.REACT_APP_BASE_API_STORAGE || 'https://api.beyondrun.com/api/v1/files',
	// production: 'http://206.189.39.120:4001/files',
	staging: 'http://206.189.39.120:4001/files',
	// development: 'http://localhost:4001/files',
	development: 'http://localhost:4000/api/v1/files',
	stagingNew: process.env.REACT_APP_BASE_API_STORAGE,
}

const BASE_STORAGE_URL = urlStorage[ENV]

export const getFaceUrl = md5 => {
	return `${BASE_STORAGE_URL}/${md5}.png`
}

export const BASE_URL = url[ENV]
export const BASE_NEW_URL = newURL[ENV]
export const BASE_URL_FILES = url[ENV] + '/files'
export const BASE_NEW_URL_FILES = newURL[ENV] + '/old/files'
