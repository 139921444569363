/*
  Author: Chornos13
 */

import ObjectHelpers from './ObjectHelpers'

class Inputs {
	DEFAULT_GLOBAL_CONFIGS = {
		onHandled: null,
		targetState: 'formData',
	}

	DEFAULT_CONFIGS = {
		saveState: true,
		savedCallback: null,
		filterValue: null,
		handleFormData: false,
		log: false,
		isPositiveNumberOnly: false,
		targetState: undefined,
	}

	DEFAULT_CONFIGS_SELECTOR = {
		saveState: true,
		savedCallback: null,
		targetData: 'value',
		noSelectedValue: '',
	}

	constructor(Component, opts) {
		this.component = Component
		this.opts = Object.assign(
			Object.assign({}, this.DEFAULT_GLOBAL_CONFIGS),
			opts,
		)
	}

	onHandled = data => {
		if (this.opts.onHandled) {
			this.opts.onHandled(data)
		}
	}

	containKey = (arrayKeys, strSearch) => {
		for (let i = 0; i < arrayKeys.length; i++) {
			const key = arrayKeys[i]
			if (key.includes(strSearch)) {
				return key
			}
		}
		return null
	}

	handleSelectorChange = (name, selectData, configs) => {
		// eslint-disable-next-line no-param-reassign
		configs = Object.assign(
			Object.assign({}, this.DEFAULT_CONFIGS_SELECTOR),
			configs,
		)
		const targetState =
			configs.targetState !== undefined
				? configs.targetState
				: this.opts.targetState
		let formData = null
		if (targetState) {
			formData = this.component.state[targetState]
		} else {
			formData = this.component.state
		}

		if (configs.targetData) {
			ObjectHelpers.setValueByKey(
				formData,
				name,
				ObjectHelpers.getValueByKey(
					selectData,
					configs.targetData,
					configs.noSelectedValue,
				),
			)
		} else {
			ObjectHelpers.setValueByKey(
				formData,
				name,
				selectData || configs.noSelectedValue,
			)
		}
		if (configs.log) {
			console.log(formData)
		}

		if (configs.saveState) {
			let objUpdateState = {}
			if (targetState) {
				objUpdateState = { formData }
			}
			this.component.setState(objUpdateState, () => {
				if (configs.savedCallback) {
					configs.savedCallback(formData)
				}
			})
		} else {
			this.onHandled(formData)
			return formData
		}
		this.onHandled(true)
		return true
	}

	handleChange(e, configs) {
		// eslint-disable-next-line no-param-reassign
		configs = Object.assign(Object.assign({}, this.DEFAULT_CONFIGS), configs)
		const targetState =
			configs.targetState !== undefined
				? configs.targetState
				: this.opts.targetState
		let formData = null
		if (targetState) {
			formData = this.component.state[targetState]
		} else {
			formData = this.component.state
		}
		let custAttr
		const target = e.target
		let value
		let name = target.name

		switch (target.type) {
			case 'file':
				if (target.multiple) {
					value = e.target.files
				} else {
					value = e.target.files[0]
				}
				break
			case 'checkbox':
				custAttr = this.containKey(Object.keys(target), 'reactEventHandlers')
				if (custAttr !== null) {
					const tGroup = target[custAttr] && target[custAttr].group
					const tValue = target[custAttr] && target[custAttr].value
					if (tGroup) {
						name = tGroup
						value = formData[name] || []
						if (target.checked) {
							value.push(tValue)
						} else {
							const index = value.indexOf(tValue)
							if (index !== -1) value.splice(index, 1)
						}
					} else {
						value = target.checked ? target.value : target.checked
					}
				} else {
					value = target.checked ? target.value : target.checked
				}
				break
			default:
				value = target.value
		}
		if (configs.filterValue) {
			value = configs.filterValue(value, formData)
		}
		if (configs.handleFormData) {
			formData = configs.handleFormData(value, formData)
		} else if (configs.isPositiveNumberOnly) {
			if (this.isPositiveInteger(value)) {
				ObjectHelpers.setValueByKey(formData, name, value)
			} else if (ObjectHelpers.getRawByKey(formData, name) === undefined) {
				ObjectHelpers.setValueByKey(formData, name, '')
			}
		} else {
			ObjectHelpers.setValueByKey(formData, name, value)
		}
		if (configs.log) {
			console.log(formData)
		}
		if (configs.saveState) {
			let objUpdateState = {}
			if (targetState) {
				objUpdateState = { formData }
			}
			this.component.setState(objUpdateState, () => {
				if (configs.savedCallback) {
					configs.savedCallback(formData, value)
				}
			})
		} else {
			this.onHandled(formData)
			return formData
		}
		this.onHandled(true)
		return true
	}

	isPositiveInteger = value => {
		return value === '' || (!isNaN(value) && parseInt(value, 0) >= 0)
	}

	get = (key, configs) => {
		if (key !== undefined && key !== null) {
			configs = Object.assign(Object.assign({}, this.DEFAULT_CONFIGS), configs)
			const targetState =
				configs.targetState !== undefined
					? configs.targetState
					: this.opts.targetState
			let formData = null
			if (targetState) {
				formData = this.component.state[targetState]
			} else {
				formData = this.component.state
			}
			if (configs.cloneValue) {
				return ObjectHelpers.getRawByKey(Object.assign({}, formData), key)
			}
			return ObjectHelpers.getRawByKey(formData, key)
		}
	}
}

export default Inputs
