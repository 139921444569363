function createObjectData(data, postFields) {
	const formData = {}
	for (let i = 0; i < postFields.length; i++) {
		const field = postFields[i]
		formData[field] = data[field]
	}
	return formData
}

function createFormData(data, postFields) {
	const formData = new FormData()
	for (let i = 0; i < postFields.length; i++) {
		const field = postFields[i]
		if (field.key && field.type) {
			switch (field.type) {
				case 'files':
					if (data[field.key]) {
						for (let x = 0; x < data[field.key].length; x++) {
							formData.append(`${field.key}`, data[field.key][x])
						}
					}
					break
				case 'array':
					if (data[field.key]) {
						for (let x = 0; x < data[field.key].length; x++) {
							formData.append(
								`${field.key}[${x}]`,
								JSON.stringify(data[field.key][x]),
							)
						}
					}
					break
				default:
					if (data[field] !== undefined) {
						formData.append(field.key, data[field.key])
					}
			}
		} else if (data[field] !== undefined) {
			formData.append(field, data[field])
		}
	}
	return formData
}

function createSimpleFormData(data, postFields) {
	const formData = new FormData()
	for (let i = 0; i < postFields.length; i++) {
		const field = postFields[i]
		if (field.key && field.type) {
			switch (field.type) {
				case 'files':
					if (data[field.key]) {
						for (let x = 0; x < data[field.key].length; x++) {
							formData.append(`${field.key}`, data[field.key][x])
						}
					}
					break
				case 'array':
					if (data[field.key]) {
						formData.append(field.key, JSON.stringify(data[field.key]))
					}
					break
				default:
					if (data[field] !== undefined) {
						formData.append(field.key, data[field.key])
					}
			}
		} else if (data[field] !== undefined) {
			formData.append(field, data[field])
		}
	}
	return formData
}

export default {
	createObjectData,
	createFormData,
	createSimpleFormData,
}
