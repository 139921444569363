function isNullOrUndefined(val) {
	return val === null || val === undefined
}

function assign(obj) {
	return Object.assign({}, obj)
}
function clone(obj) {
	return Object.assign({}, obj)
}

function isObject(val) {
	if (val === null) {
		return false
	}
	return typeof val === 'function' || typeof val === 'object'
}

const DEFAULT_CONFIGS_GET_VALUE = {
	prefixValue: null,
	suffixValue: null,
	isHideValue: false,
	valueHide: '-',
}

function getValueByKey(obj, targets, valueIfNull = '', configs) {
	const curConfigs = Object.assign(clone(DEFAULT_CONFIGS_GET_VALUE), configs)
	if (isNullOrUndefined(obj)) {
		return valueIfNull
	}
	const targetSplit = targets.split('.')
	let value = null
	for (const target of targetSplit) {
		if (value) {
			value = value[target]
		} else {
			value = obj[target]
		}
		if (isNullOrUndefined(value)) {
			return valueIfNull
		}
	}

	if (curConfigs.prefixValue) {
		value = `${curConfigs.prefixValue}${value}`
	}
	if (curConfigs.suffixValue) {
		value = `${value}${curConfigs.suffixValue}`
	}
	if (curConfigs.isHideValue) {
		value = curConfigs.valueHide
	}

	return value
}

function setValueByKey(obj, targets, val) {
	if (isNullOrUndefined(obj)) {
		return false
	}
	if (!targets) {
		obj = val
		return true
	}
	const targetSplit = targets.split('.')
	let value = null
	let setterValue = null
	for (let i = 0; i < targetSplit.length; i++) {
		const target = targetSplit[i]
		if (value) {
			setterValue = value
			value = value[target]
		} else {
			setterValue = obj
			value = obj[target]
		}
		if (isNullOrUndefined(value) && i !== targetSplit.length - 1) {
			return false
		}
		if (i === targetSplit.length - 1) {
			setterValue[target] = val
		}
	}
	return true
}

function getRawByKey(obj, targets) {
	if (isNullOrUndefined(obj)) {
		return obj
	}
	const targetSplit = targets.split('.')
	let value = null
	for (const target of targetSplit) {
		if (value) {
			value = value[target]
		} else {
			value = obj[target]
		}
		if (isNullOrUndefined(value)) {
			return value
		}
	}
	return value
}

const ObjectHelpers = {
	getValueByKey,
	getRawByKey,
	setValueByKey,
	clone,
	assign,
	isObject,
}

export default ObjectHelpers
