import React, { Component } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	Dropdown,
} from 'reactstrap'
import PropTypes from 'prop-types'

import {
	AppHeaderDropdown,
	AppNavbarBrand,
	AppSidebarToggler,
} from '@coreui/react'
import logo from '../../assets/img/brand/logo.png'
import sygnet from '../../assets/img/brand/sygnet.png'
import JwtHelpers from '../../utils/JwtHelpers'
import ObjectHelpers from '../../utils/ObjectHelpers'

const propTypes = {
	children: PropTypes.node,
}

const defaultProps = {}

class DefaultHeader extends Component {
	state = {}
	render() {
		return (
			<React.Fragment>
				<AppSidebarToggler className="d-lg-none" display="md" mobile />
				<AppNavbarBrand
					full={{
						src: logo,
						width: 89,
						height: 25,
						alt: 'BeyondRun Logo',
					}}
					minimized={{
						src: sygnet,
						width: 30,
						height: 30,
						alt: 'BeyondRun Logo',
					}}
				/>
				<AppSidebarToggler className="d-md-down-none" display="lg" />

				<Nav className="ml-auto" navbar>
					<NavItem className="d-md-down-none">
						{ObjectHelpers.getValueByKey(
							JwtHelpers.getCurrentUser(),
							'email',
							'',
						)}
					</NavItem>
					<AppHeaderDropdown direction="down">
						<Dropdown
							isOpen={this.state.open}
							toggle={() => {
								this.setState(prevState => {
									return {
										open: !prevState.open,
									}
								})
							}}
						>
							<DropdownToggle nav>
								<img
									src={'assets/img/avatars/6.jpg'}
									className="img-avatar"
									alt="admin@bootstrapmaster.com"
								/>
							</DropdownToggle>
							<DropdownMenu right style={{ right: 'auto' }}>
								<DropdownItem header tag="div" className="text-center">
									<strong>Settings</strong>
								</DropdownItem>
								<DropdownItem
									onClick={() => {
										this.props.history.push('/profile')
									}}
								>
									<i className="fa fa-user" /> Profile
								</DropdownItem>
								<DropdownItem
									onClick={() => {
										JwtHelpers.removeCurrentToken()
										JwtHelpers.redirectUser(null)
									}}
								>
									<i className="fa fa-lock" /> Logout
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</AppHeaderDropdown>
				</Nav>
				{/* <AppAsideToggler className="d-md-down-none" />*/}
				{/* <AppAsideToggler className="d-lg-none" mobile />*/}
			</React.Fragment>
		)
	}
}

DefaultHeader.propTypes = propTypes
DefaultHeader.defaultProps = defaultProps

export default DefaultHeader
