import axios from 'axios'
import { BASE_NEW_URL, BASE_URL } from '../constants'
import Requests from '../utils/Requests'

axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common.token = localStorage.getItem('token')

export const apiNew = axios.create({
	baseURL: BASE_NEW_URL,
	transformRequest: [
		(data, headers) => {
			console.log(data)
			headers.token = localStorage.getItem('token')
			return data
		},
		...axios.defaults.transformRequest,
	],
})

// Login
function postLogin(data) {
	return axios.post('/login', data)
}

// Dashboard
function getDashboard() {
	return axios.get('/dashboard')
}

function getDashboardRace(paramEncoded) {
	return axios.get(`/dashboard-race?${paramEncoded}`)
}

function getDashboardTicketbox(EventId) {
	return axios.get(`/dashboard-ticketbox/${EventId}`)
}

function getDashboardRacepak(EventId) {
	return axios.get(`/dashboard-racepak/${EventId}`)
}

function getDashboardDetailPayment(EventId) {
	return axios.get(`/dashboard/payment/${EventId}`)
}

// Blog
function getBlog(paramEncoded) {
	return axios.get(`/blog?${paramEncoded}`)
}

function getBlogById(BlogId) {
	return axios.get(`/blog/${BlogId}`)
}

function createBlog(data) {
	const formData = Requests.createFormData(data, [
		'judul',
		'deskripsi',
		'fileSlider1',
		'fileSlider2',
		'fileSlider3',
		'file',
	])
	return axios.post('/blog', formData)
}

function updateBlog(data) {
	const formData = Requests.createFormData(data, [
		'id',
		'judul',
		'deskripsi',
		'fileSlider1',
		'fileSlider2',
		'fileSlider3',
		'file',
	])
	return axios.put('/blog', formData)
}

function deleteBlog(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/blog', { data: formData })
}

// Content Page
function getContentPage(paramEncoded) {
	return axios.get(`/content-page?${paramEncoded}`)
}

function getContentPageById(ContentPageId) {
	return axios.get(`/content-page/${ContentPageId}`)
}

function updateContentPage(id, data) {
	const formData = Requests.createFormData(data, ['content', 'fileHtml'])
	return axios.put(`/content-page/${id}`, formData)
}

// Challenge
function getChallenge(paramEncoded) {
	return axios.get(`/challenge?${paramEncoded}`)
}

function getChallengeById(ChallengeId) {
	return axios.get(`/challenge/${ChallengeId}`)
}

function createChallenge(data) {
	const formData = Requests.createFormData(data, [
		'CompanyId',
		'nama',
		'kode',
		'file',
		{ key: 'ChallengeCategories', type: 'array' },
		{ key: 'deleteIdsCategory', type: 'array' },
		'tglChallenge',
		'tglTutup',
		'CityId',
		'deskripsi',
		'idAlias',
		'tglBuka',
		'ChallengeTypeId',
		'DeviceTypeId',
		'ChallengeCategoryTypeId',
		'startTime',
		'endTime',
	])
	return axios.post('/challenge', formData)
}

function updateChallenge(data) {
	const formData = Requests.createFormData(data, [
		'id',
		'CompanyId',
		'nama',
		'kode',
		'file',
		{ key: 'ChallengeCategories', type: 'array' },
		{ key: 'deleteIdsCategory', type: 'array' },
		'tglChallenge',
		'CityId',
		'deskripsi',
		'tglTutup',
		'idAlias',
		'tglBuka',
		'ChallengeTypeId',
		'DeviceTypeId',
		'ChallengeCategoryTypeId',
		'startTime',
		'endTime',
	])
	return axios.put('/challenge', formData)
}

function deleteChallenge(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/challenge', { data: formData })
}

function getPaymentVirtual(paramEncoded) {
	return apiNew.get(`/payment-virtual?${paramEncoded}`)
}

function acceptPayment(kodeTransaksi) {
	return apiNew.post(`/payment/${kodeTransaksi}/accept`)
}

function cancelPayment(kodeTransaksi) {
	return apiNew.post(`/payment/${kodeTransaksi}/cancel`)
}

function generateExcelPaymentVirtual() {
	const query = `?page=1&pageSize=10`
	return apiNew.get(`/payment-virtual/generate-excel${query}`, {
		responseType: 'blob',
	})
}

function generateExcelRacepackVirtual() {
	const query = `?page=1&pageSize=10`
	return apiNew.get(`/racepak/generate-excel${query}`, {
		responseType: 'blob',
	})
}

function generateExcelVoucherVirtual() {
	const query = `?page=1&pageSize=10`
	return apiNew.get(`/voucher-virtual/generate-excel${query}`, {
		responseType: 'blob',
	})
}

function getRacepakVirtual(paramEncoded) {
	return apiNew.get(`/racepak-virtual?${paramEncoded}`)
}

function getPesertaVirtual(paramEncoded) {
	return apiNew.get(`/peserta-virtual?${paramEncoded}`)
}

// Event Virtual
function getEventVirtual(paramEncoded) {
	return apiNew.get(`/event-virtual-sport?${paramEncoded}`)
}

function createEventVirtual(data) {
	const formData = Requests.createFormData(data, [
		'CompanyId',
		'nama',
		'kode',
		'filename',
		{ key: 'EventCategoriesVirtualSports', type: 'array' },
		{ key: 'deleteIdsCategory', type: 'array' },
		'tglEventMulai',
		'tglBukaPendaftaran',
		'tglTutupPendaftaran',
		'tglEventSelesai',
		'CityId',
		'deskripsi',
		'tglTutup',
		'idAlias',
		'fileShirtSize',
		'fileCertificate',
		'referral',
		'isWithRacepak',
	])
	return apiNew.post('/event-virtual-sport', formData)
}

function updateEventVirtual(data) {
	const formData = Requests.createFormData(data, [
		'id',
		'CompanyId',
		'nama',
		'kode',
		'filename',
		{ key: 'EventCategoriesVirtualSports', type: 'array' },
		{ key: 'deleteIdsCategory', type: 'array' },
		'tglEventMulai',
		'tglBukaPendaftaran',
		'tglTutupPendaftaran',
		'tglEventSelesai',
		'CityId',
		'deskripsi',
		'tglTutup',
		'idAlias',
		'fileShirtSize',
		'fileCertificate',
		'fileLogoTiket',
		'referral',
		'isWithRacepak',
	])
	return apiNew.put(`/event-virtual-sport/${data.id}`, formData)
}

function deleteEventVirtual(id) {
	return apiNew.delete(`/event-virtual-sport/${id}`)
}

// Event
function getEvent(paramEncoded) {
	return axios.get(`/event?${paramEncoded}`)
}

function getEventById(EventId) {
	return axios.get(`/event/${EventId}`)
}

function createEvent(data) {
	const formData = Requests.createFormData(data, [
		'CompanyId',
		'nama',
		'kode',
		'file',
		{ key: 'EventCategories', type: 'array' },
		{ key: 'deleteIdsCategory', type: 'array' },
		'tglEvent',
		'tglTutup',
		'CityId',
		'deskripsi',
		'idAlias',
		'tglBuka',
		'fileShirtSize',
		'fileLogoTiket',
		'fileCertificate',
		'referral',
		'committeeName',
	])
	return axios.post('/event', formData)
}

function updateEvent(data) {
	const formData = Requests.createFormData(data, [
		'id',
		'CompanyId',
		'nama',
		'kode',
		'file',
		{ key: 'EventCategories', type: 'array' },
		{ key: 'deleteIdsCategory', type: 'array' },
		'tglEvent',
		'CityId',
		'deskripsi',
		'tglTutup',
		'idAlias',
		'tglBuka',
		'fileShirtSize',
		'fileCertificate',
		'fileLogoTiket',
		'referral',
		'committeeName',
	])
	return axios.put('/event', formData)
}

function deleteEvent(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/event', { data: formData })
}

// Event Photo
function getEventPhoto(paramEncoded) {
	return axios.get(`/event-photos?${paramEncoded}`)
}

function postEventPhoto(data) {
	const formData = Requests.createFormData(data, [
		'EventId',
		{ key: 'images', type: 'files' },
	])
	return apiNew.post('/event-photos', formData)
}

function deleteEventPhoto(data) {
	return apiNew.delete('/event-photos/' + data?.id)
}

// Master Jenis Virtual
function getMasterJenisVirtual(paramEncoded) {
	return apiNew.get(`/master-jenis-virtual?${paramEncoded}`)
}

// Company
function getMasterJenisKegiatan(paramEncoded) {
	return apiNew.get(`/master-jenis-kegiatan?${paramEncoded}`)
}

// Company
function getCompany(paramEncoded) {
	return axios.get(`/company?${paramEncoded}`)
}

function createCompany(data) {
	const formData = Requests.createObjectData(data, ['nama'])
	return axios.post('/company', formData)
}

function updateCompany(data) {
	const formData = Requests.createObjectData(data, ['id', 'nama'])
	return axios.put('/company', formData)
}

function deleteCompany(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/company', { data: formData })
}

// Challenge Type
function getChallengeType(paramEncoded) {
	return axios.get(`/challenge-type?${paramEncoded}`)
}

function createChallengeType(data) {
	const formData = Requests.createObjectData(data, ['nama'])
	return axios.post('/challenge-type', formData)
}

function updateChallengeType(data) {
	const formData = Requests.createObjectData(data, ['id', 'nama'])
	return axios.put('/challenge-type', formData)
}

function deleteChallengeType(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/challenge-type', { data: formData })
}

// Challenge Category Type
function getChallengeCategoryType(paramEncoded) {
	return axios.get(`/challenge-category-type?${paramEncoded}`)
}

function createChallengeCategoryType(data) {
	const formData = Requests.createObjectData(data, ['nama'])
	return axios.post('/challenge-category-type', formData)
}

function updateChallengeCategoryType(data) {
	const formData = Requests.createObjectData(data, ['id', 'nama'])
	return axios.put('/challenge-category-type', formData)
}

function deleteChallengeCategoryType(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/challenge-category-type', { data: formData })
}

// Device Type
function getDeviceType(paramEncoded) {
	return axios.get(`/device-type?${paramEncoded}`)
}

function createDeviceType(data) {
	const formData = Requests.createObjectData(data, ['nama'])
	return axios.post('/device-type', formData)
}

function updateDeviceType(data) {
	const formData = Requests.createObjectData(data, ['id', 'nama'])
	return axios.put('/device-type', formData)
}

function deleteDeviceType(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/device-type', { data: formData })
}

// Matching Photo
function getMatchingPhoto(paramEncoded) {
	return apiNew.get(`/matching-photo?${paramEncoded}`)
}

function postMatchingPhoto(data) {
	const formData = Requests.createFormData(data, [
		'EventId',
		{ key: 'images', type: 'files' },
		'AthleteId',
	])
	return apiNew.post('/matching-photo', formData)
}

function postSingleMatchingPhoto(data, config) {
	const formData = Requests.createFormData(data, [
		'EventId',
		'image',
		'AthleteId',
	])
	return apiNew.post('/matching-photo/single', formData, config)
}

// Athlete
function getAthlete(paramEncoded) {
	return axios.get(`/athlete?${paramEncoded}`)
}

function getAthleteOnly(paramEncoded) {
	return axios.get(`/athlete-only?${paramEncoded}`)
}

function getAthleteById(AthleteId) {
	return axios.get(`/athlete/${AthleteId}`)
}

function createAthlete(data) {
	const formData = Requests.createFormData(data, [
		'username',
		'fullname',
		'email',
		'telp',
		'images',
		'MasterBloodId',
		'MasterGenderId',
		'MasterShirtSizeId',
		'noTelpDarurat',
		'namaTelpDarurat',
		'faceMd5',
	])
	return axios.post('/athlete', formData)
}

function updateAthlete(data) {
	const formData = Requests.createFormData(data, [
		'id',
		'username',
		'fullname',
		'email',
		'telp',
		'images',
		'MasterBloodId',
		'MasterGenderId',
		'MasterShirtSizeId',
		'noTelpDarurat',
		'namaTelpDarurat',
		'faceMd5',
	])
	return axios.put('/athlete', formData)
}

function deleteAthlete(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/athlete', { data: formData })
}

// Racepak
function getRacepak(paramEncoded) {
	return axios.get(`/racepak?${paramEncoded}`)
}

function getRacepakLite(paramEncoded) {
	return apiNew.get(`/racepak?${paramEncoded}`)
}

function createRacepak(data) {
	const formData = Requests.createFormData(data, [
		'EventId',
		'AthleteId',
		'bib',
		'file',
		'MasterShirtSizeId',
		'noTelpDarurat',
		'gunTime',
	])
	return axios.post('/racepak', formData)
}

function updateRacepak(data) {
	const formData = Requests.createFormData(data, [
		'id',
		'EventId',
		'AthleteId',
		'bib',
		'file',
		'MasterShirtSizeId',
		'noTelpDarurat',
		'gunTime',
	])
	return axios.put('/racepak', formData)
}

function updateAmbilRacepak(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'statusAmbilRacepak',
		'keteranganRacepak',
	])
	return axios.put('/racepak/status', formData)
}

function bulkUpdateRacepak(data) {
	const formData = Requests.createFormData(data, ['file'])
	return axios.post('/racepak/excel', formData)
}

function deleteRacepak(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/racepak', { data: formData })
}
// Start Matching Photo
function startMatchingPhotos(data) {
	return axios.post('/start-matching-photo', data)
}

// Manual Matching

function postManualMatching(data) {
	const formData = Requests.createFormData(data, [
		'PhotoEventId',
		{ key: 'listMatching', type: 'array' },
	])
	return apiNew.post('/manual-matching', formData)
}

// Photo Event

function deletePhotoEvent(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return apiNew.delete('/photo-event', { data: formData })
}

function updatePhotoEvent(data) {
	const formData = Requests.createObjectData(data, ['id', 'status'])
	return axios.put('/photo-event', formData)
}

function deleteMatchingAthlete(data) {
	const formData = Requests.createObjectData(data, ['id', 'PhotoEventId'])
	return apiNew.delete('/matching-photo', { data: formData })
}

// Face Photo Athlete
function updateFacePhotoAthlete(data) {
	const formData = Requests.createObjectData(data, ['id', 'wrong'])
	return axios.put('/face-photo-athlete', formData)
}

// Photo Athlete
function deletePhotoAthlete(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/photo-athlete', { data: formData })
}

// User
function getUser(paramEncoded) {
	return axios.get(`/user?${paramEncoded}`)
}

function getUserById(UserId) {
	return axios.get(`/user/${UserId}`)
}

function createUser(data) {
	const formData = Requests.createObjectData(data, [
		'RoleId',
		'username',
		'email',
		'fullname',
		'newPassword',
	])
	return axios.post('/user', formData)
}

function updateUser(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'RoleId',
		'username',
		'email',
		'fullname',
		'newPassword',
		'oldPassword',
	])
	return axios.put('/user', formData)
}

function deleteUser(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/user', { data: formData })
}

// Role
function getRole(paramEncoded) {
	return axios.get(`/role?${paramEncoded}`)
}

function createRole(data) {
	const formData = Requests.createObjectData(data, [
		'nama',
		'MasterNavIds',
		'deleteNavIds',
		'permissions',
		'CompanyId',
	])
	return axios.post('/role', formData)
}

function updateRole(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'nama',
		'MasterNavIds',
		'deleteNavIds',
		'permissions',
		'CompanyId',
	])
	return axios.put('/role', formData)
}

function deleteRole(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/role', { data: formData })
}
// Master Nav
function getMasterNav(paramEncoded) {
	return axios.get(`/master-nav?${paramEncoded}`)
}

// Rotate
function postRotate(data) {
	const formData = Requests.createObjectData(data, [
		'PhotoAthleteId',
		'degree',
		'PhotoEventId',
	])
	return apiNew.post('/rotate', formData)
}

// Payment
function getPayment(paramEncoded) {
	return axios.get(`/payment?${paramEncoded}`)
}

function getPaymentById(id) {
	return axios.get(`/payment/${id}`)
}

function updatePayment(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'status',
		'MasterRejectReasonId',
	])
	return axios.put('/payment', formData)
}

function resendPaymentEmail(data) {
	const formData = Requests.createObjectData(data, ['id', 'resendStatus'])
	return axios.post('/resend-payment-email', formData)
}

function resendPaymentEmailVirtual(data) {
	const formData = Requests.createObjectData(data, ['id', 'resendStatus'])
	return apiNew.post('/payment/email-resend', formData)
}

// City
function getCity(paramEncoded) {
	return axios.get(`/city?${paramEncoded}`)
}

// Master Category
function getMasterCategory(paramEncoded) {
	return axios.get(`/master-category?${paramEncoded}`)
}

function createMasterCategory(data) {
	const formData = Requests.createObjectData(data, [
		'nama',
		'ultra',
		'certificateCategory',
	])
	return axios.post('/master-category', formData)
}

function updateMasterCategory(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'nama',
		'ultra',
		'certificateCategory',
	])
	return axios.put('/master-category', formData)
}

function deleteMasterCategory(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/master-category', { data: formData })
}

// Master Challenge Category
function getMasterChallengeCategory(paramEncoded) {
	return axios.get(`/master-challenge-category?${paramEncoded}`)
}

function createMasterChallengeCategory(data) {
	const formData = Requests.createObjectData(data, ['nama', 'ultra'])
	return axios.post('/master-challenge-category', formData)
}

function updateMasterChallengeCategory(data) {
	const formData = Requests.createObjectData(data, ['id', 'nama', 'ultra'])
	return axios.put('/master-challenge-category', formData)
}

function deleteMasterChallengeCategory(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/master-challenge-category', { data: formData })
}

// Master Blood
function getMasterBlood(paramEncoded) {
	return axios.get(`/master-blood?${paramEncoded}`)
}

// Master Identity Card
function getMasterIdentityCard(paramEncoded) {
	return axios.get(`/master-identity-card?${paramEncoded}`)
}

// Master Shirt Size
function getMasterShirtSize(paramEncoded) {
	return axios.get(`/master-shirt-size?${paramEncoded}`)
}

// Master Gender
function getMasterGender(paramEncoded) {
	return axios.get(`/master-gender?${paramEncoded}`)
}

// Master Country
function getMasterCountry(paramEncoded) {
	return axios.get(`/master-country?${paramEncoded}`)
}

// Master Reject Reason
function getMasterRejectReason(paramEncoded) {
	return axios.get(`/master-reject-reason?${paramEncoded}`)
}

// Master Voucher Type
function getMasterVoucherType(paramEncoded) {
	return axios.get(`/master-voucher-type?${paramEncoded}`)
}

// Master Voucher
function getMasterVoucher(paramEncoded) {
	return axios.get(`/master-voucher?${paramEncoded}`)
}

function getMasterVoucherVirtual(paramEncoded) {
	return apiNew.get(`/master-voucher-virtual?${paramEncoded}`)
}

function createMasterVoucher(data) {
	const formData = Requests.createObjectData(data, [
		'kode',
		'MasterVoucherTypeId',
		'quantityFree',
		'discountPercentage',
		'quantityProduct',
		'EventId',
		'multiUse',
		'MasterCategoryId',
	])
	return axios.post('/master-voucher', formData)
}

function createMasterVoucherVirtual(data) {
	return apiNew.post('/master-voucher-virtual', data)
}

function updateMasterVoucher(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'kode',
		'MasterVoucherTypeId',
		'quantityFree',
		'discountPercentage',
		'quantityProduct',
		'EventId',
		'multiUse',
		'MasterCategoryId',
	])
	return axios.put('/master-voucher', formData)
}

function updateMasterVoucherVirtual(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'kode',
		'MasterVoucherTypeId',
		'quantityFree',
		'discountPercentage',
		'quantityProduct',
		'EventId',
		'isMultiUse',
		'MasterCategoryId',
	])
	return apiNew.put(`/master-voucher-virtual/${data.id}`, formData)
}

function deleteMasterVoucherVirtual(id) {
	return apiNew.delete(`/master-voucher-virtual/${id}`)
}

function deleteMasterVoucher(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/master-voucher', { data: formData })
}

// Voucher
function getVoucher(paramEncoded) {
	return axios.get(`/voucher?${paramEncoded}`)
}

function getVoucherVirtual(paramEncoded) {
	return apiNew.get(`/voucher-virtual?${paramEncoded}`)
}

function createVoucher(data) {
	const formData = Requests.createObjectData(data, [
		'MasterVoucherId',
		'nama',
		'kode',
		'tglBerlaku',
		'limit',
		'jumlahVoucher',
		'email',
	])
	return axios.post('/voucher', formData)
}

function createVoucherVirtual(data) {
	return apiNew.post('/voucher-virtual', data)
}

function updateVoucher(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'MasterVoucherId',
		'nama',
		'kode',
		'tglBerlaku',
		'limit',
		'jumlahVoucher',
		'email',
	])
	return axios.put('/voucher', formData)
}

function updateVoucherVirtual(data) {
	const formData = Requests.createObjectData(data, [
		'id',
		'MasterVoucherVirtualRunId',
		'nama',
		'kode',
		'tglBerlaku',
		'limit',
		'jumlahVoucher',
		'email',
	])
	return apiNew.put(`/voucher-virtual/${data.id}`, formData)
}

function deleteVoucher(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/voucher', { data: formData })
}

function deleteVoucherVirtual(id) {
	return apiNew.delete(`/voucher-virtual/${id}`)
}

function createPayment(data) {
	const formData = Requests.createFormData(data, [
		'EventId',
		{ key: 'listParticipant', type: 'array' },
		{ key: 'images', type: 'files' },
		'convenienceFee',
		'kodeVoucher',
		'verified',
	])
	return axios.post('/payment', formData)
}

// Generate Pdf
function generateTicketPayment(PaymentId) {
	return axios.get(`/generate-pdf/${PaymentId}`)
}
function generateTicketRacepak(RacepakId) {
	return axios.get(`/generate-ticket-racepak/${RacepakId}`)
}

// Resend Email

function resendRacepakEmail(RacepakId) {
	return axios.post(`/resend-racepak-email/${RacepakId}`)
}

function addEventGalleryFromPhotoEvent(PhotoEventId, data) {
	return apiNew.post(`/event-photos/add-photo-event/${PhotoEventId}`, data)
}

// Participant Challenge
function getParticipantChallenge(paramEncoded) {
	return axios.get(`/participant-challenge?${paramEncoded}`)
}

function createParticipantChallenge(data) {
	return axios.post('/participant-challenge', data)
}

function updateParticipantChallenge(data) {
	return axios.put('/participant-challenge', data)
}

function deleteParticipantChallenge(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/participant-challenge', { data: formData })
}

// Participant Challenge
function getTeamChallenge(paramEncoded) {
	return axios.get(`/team-challenge?${paramEncoded}`)
}

function createTeamChallenge(data) {
	const formData = Requests.createSimpleFormData(data, [
		'nama',
		'ChallengeId',
		{ key: 'ParticipantChallenges', type: 'array' },
	])
	return axios.post('/team-challenge', formData)
}

function updateTeamChallenge(data) {
	const formData = Requests.createSimpleFormData(data, [
		'id',
		'nama',
		'ChallengeId',
		{ key: 'ParticipantChallenges', type: 'array' },
	])
	return axios.put('/team-challenge', formData)
}

function deleteTeamChallenge(data) {
	const formData = Requests.createObjectData(data, ['id'])
	return axios.delete('/team-challenge', { data: formData })
}

function getConfigFaceRecognition() {
	return axios.get('/config-face-recognition')
}

function updateConfigFaceRecognition(data) {
	return axios.put('/config-face-recognition', data)
}

function updateRacepakVirtual(id, data) {
	return apiNew.put(`/racepak-virtual/${id}`, data)
}

function updateMasterEkspedisi(id, data) {
	return apiNew.put(`/master-ekspedisi/${id}`, data)
}

function createMasterEkspedisi(data) {
	return apiNew.post(`/master-ekspedisi`, data)
}

function deleteMasterEkspedisi(id) {
	return apiNew.delete(`/master-ekspedisi/${id}`)
}

function getWatermarks(paramEncoded) {
	return apiNew.get(`/watermarks?${paramEncoded}`)
}
function getWatermarkPositions() {
	return apiNew.get(`/watermarks-position`)
}
function getWatermarksById(id) {
	return apiNew.get(`/watermarks/${id}`)
}
function createWatermarks(data) {
	return apiNew.post(`/watermarks`, data)
}
function updateWatermarks(id, data) {
	return apiNew.put(`/watermarks/${id}`, data)
}
function deleteWatermarks(id) {
	return apiNew.delete(`/watermarks/${id}`)
}
const ApiCall = {
	deleteMasterEkspedisi,
	createMasterEkspedisi,
	updateMasterEkspedisi,
	updateRacepakVirtual,
	updateConfigFaceRecognition,
	getConfigFaceRecognition,
	postSingleMatchingPhoto,
	getTeamChallenge,
	createTeamChallenge,
	updateTeamChallenge,
	deleteTeamChallenge,
	addEventGalleryFromPhotoEvent,
	getBlog,
	createBlog,
	updateBlog,
	deleteBlog,
	getContentPage,
	getContentPageById,
	updateContentPage,
	getDashboardRacepak,
	bulkUpdateRacepak,
	resendRacepakEmail,
	generateTicketRacepak,
	getDashboardTicketbox,
	generateTicketPayment,
	generateExcelPaymentVirtual,
	generateExcelRacepackVirtual,
	generateExcelVoucherVirtual,
	deleteVoucher,
	deleteVoucherVirtual,
	createPayment,
	getMasterCountry,
	getMasterIdentityCard,
	updateVoucher,
	updateVoucherVirtual,
	createVoucher,
	createVoucherVirtual,
	deleteMasterVoucher,
	updateMasterVoucher,
	updateMasterVoucherVirtual,
	deleteMasterVoucherVirtual,
	createMasterVoucher,
	createMasterVoucherVirtual,
	getMasterVoucherType,
	getMasterVoucher,
	getMasterVoucherVirtual,
	getVoucher,
	getVoucherVirtual,
	getDashboardDetailPayment,
	getEventById,
	resendPaymentEmail,
	resendPaymentEmailVirtual,
	getDashboardRace,
	getPaymentById,
	getMasterRejectReason,
	updatePayment,
	getMasterGender,
	getMasterShirtSize,
	getMasterBlood,
	getMasterCategory,
	createMasterCategory,
	updateMasterCategory,
	deleteMasterCategory,
	getCity,
	getPayment,
	getUserById,
	getEventPhoto,
	postEventPhoto,
	deleteEventPhoto,
	deleteUser,
	deleteRacepak,
	deleteCompany,
	deleteEvent,
	deleteAthlete,
	getAthleteById,
	postRotate,
	updateUser,
	deleteRole,
	updateRole,
	getMasterNav,
	createUser,
	createRole,
	getRole,
	getUser,
	postLogin,
	updatePhotoEvent,
	deletePhotoAthlete,
	updateFacePhotoAthlete,
	deleteMatchingAthlete,
	deletePhotoEvent,
	getMasterJenisKegiatan,
	getDashboard,
	postManualMatching,
	updateRacepak,
	createRacepak,
	getCompany,
	createCompany,
	updateCompany,
	updateEvent,
	createEvent,
	updateAthlete,
	startMatchingPhotos,
	getRacepak,
	createAthlete,
	getAthlete,
	getAthleteOnly,
	getMatchingPhoto,
	postMatchingPhoto,
	getEventVirtual,
	getRacepakVirtual,
	getPesertaVirtual,
	getPaymentVirtual,
	acceptPayment,
	cancelPayment,
	getMasterJenisVirtual,
	createEventVirtual,
	updateEventVirtual,
	deleteEventVirtual,
	getEvent,
	updateAmbilRacepak,
	updateChallenge,
	createChallenge,
	getChallenge,
	getChallengeById,
	deleteChallenge,
	getMasterChallengeCategory,
	createMasterChallengeCategory,
	updateMasterChallengeCategory,
	deleteMasterChallengeCategory,
	getChallengeType,
	createChallengeType,
	updateChallengeType,
	deleteChallengeType,
	getDeviceType,
	createDeviceType,
	updateDeviceType,
	deleteDeviceType,
	getChallengeCategoryType,
	createChallengeCategoryType,
	updateChallengeCategoryType,
	deleteChallengeCategoryType,
	getBlogById,
	getParticipantChallenge,
	createParticipantChallenge,
	updateParticipantChallenge,
	deleteParticipantChallenge,
	getRacepakLite,
	getWatermarks,
	getWatermarksById,
	createWatermarks,
	updateWatermarks,
	deleteWatermarks,
	getWatermarkPositions,
}

export default ApiCall
