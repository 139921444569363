import jwt from 'jsonwebtoken'
import axios from 'axios'
import ObjectHelpers from './ObjectHelpers'

function removeCurrentToken() {
	localStorage.removeItem('token')
	axios.defaults.headers.common.token = null
}

function setCurrentToken(token) {
	localStorage.setItem('token', token)
	axios.defaults.headers.common.token = localStorage.getItem('token')
	return getCurrentUser()
}

function getCurrentUser() {
	const token = localStorage.getItem('token')
	const user = ObjectHelpers.getValueByKey(jwt.decode(token), 'user', null)
	return user
}

function redirectUser(user) {
	const location =
		ObjectHelpers.getValueByKey(user, 'Role.Navigations.0.url', null) ||
		ObjectHelpers.getValueByKey(user, 'Role.Navigations.0.children.0.url', null)
	if (location) {
		window.location = '/#' + location
	} else {
		window.location = '/#/login'
		return false
	}
	return true
}

export default {
	getCurrentUser,
	setCurrentToken,
	redirectUser,
	removeCurrentToken,
}
