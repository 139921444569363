import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import numeral from 'numeral'
import moment from 'moment-timezone'
import './App.scss'

// Containers
import { DefaultLayout } from './containers'
// Pages
import { Login, Page404, Page500, Page403, Register } from './views/Pages'

// import { renderRoutes } from 'react-router-config';

class App extends Component {
	constructor(props) {
		super(props)
		moment.tz.setDefault('Asia/Jakarta')
		numeral.register('locale', 'id', {
			delimiters: {
				thousands: '.',
				decimal: ',',
			},
			abbreviations: {
				thousand: 'Ribu',
				million: 'Juta',
				billion: 'Milliar',
				trillion: 'Trilliun',
			},
			currency: {
				symbol: 'Rp',
			},
		})
		numeral.locale('id')
	}

	render() {
		return (
			<HashRouter>
				<Switch>
					<Route exact path="/login" name="Login Page" component={Login} />
					<Route
						exact
						path="/register"
						name="Register Page"
						component={Register}
					/>
					<Route exact path="/403" name="Page 403" component={Page403} />
					<Route exact path="/404" name="Page 404" component={Page404} />
					<Route exact path="/500" name="Page 500" component={Page500} />
					<Route path="/" name="Home" component={DefaultLayout} />
				</Switch>
			</HashRouter>
		)
	}
}

export default App
